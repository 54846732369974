import Vue from 'vue'
import VueRouter from 'vue-router'
// import vivestCRM from './modules/vivest-crm'
import Login from "../views/Login/Login";
import RedefenirSenha from "../views/Login/RedefenirSenha";
import CRMTemplate from "../views/Template/CRMTemplate";
import Workflow from "../views/Workflow/Workflow";
import Dashboard from "../views/Dashboard/Dashboard";
import Users from "../views/Users/Users";
import Tree from "../views/Tree/Tree";
import Executions from "../views/Executions/Executions";

Vue.use(VueRouter)

const routes = [{
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/redefenir-senha',
        name: 'RedefenirSenha',
        component: RedefenirSenha
    },
    {
        path: '/',
        name: 'Home',
        component: CRMTemplate,
        redirect: '/login',
        meta: {},
        children: [{
                path: '/Workflow',
                name: 'Workflow',
                component: Workflow,
                meta: {},
            },
            {
                path: '/Executions',
                name: 'Executions',
                component: Executions,
                meta: {},
            },
            {
                path: '/Tree',
                name: 'Tree',
                component: Tree,
                meta: {},
            },
            {
                path: '/Dashboard',
                name: 'Dashboard',
                component: Dashboard,
                meta: {},
            },
            {
                path: '/Users',
                name: 'Users',
                component: Users,
                meta: {},
            },
        ]
    },
]

const router = new VueRouter({
   // mode: 'history',
    routes,
})

export default router
